import axios from 'axios';
import { API_BASE } from '../utils/constants';
import AxiosInterceptor from "../utils/AxiosInterceptor";
const API_URL = API_BASE + "adminAccess/";

const apiAuth = {
  resetPassword:   (value) => AxiosInterceptor().post(API_BASE + "/update-password", value),
  getAdminDetails: (value) => AxiosInterceptor().get(API_BASE + "/getAdminDetails", value),
  changePassword : (value) => AxiosInterceptor().post(API_BASE + "/changePassword", value),
  getSetting: (value) => AxiosInterceptor().get(API_BASE + "/getSetting", value),  
  updateSetting : (value) => AxiosInterceptor().post(API_BASE + "/updateSetting", value),

};

export default apiAuth;
