import AxiosInterceptor from "../utils/AxiosInterceptor";

const categoryManagementApi = {
  add: (value) => AxiosInterceptor().post('/addCategory', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllCategories`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneCategory/${values.id}`),
  updateCategory: (values) => AxiosInterceptor().post(`/updateCategory`, values),
  deleteCategory: (values) => AxiosInterceptor().get(`/deletecategory/${values.id}`, ),
};

const productManagementApi = {
  add: (value) => AxiosInterceptor().post('/addProduct', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllProducts`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneProduct/${values.id}`),
  updateProduct: (values) => AxiosInterceptor().post(`/updateProduct`, values),
  changeStatus: (values) => AxiosInterceptor().post(`/changeStatusProduct`, values),
  deleteProduct: (values) => AxiosInterceptor().get(`/deleteProduct/${values.id}`, ),
  getAllCategoryForProducts: (values) => AxiosInterceptor().post('/getAllDropdownCategory', values)
};

const dealerManagementApi = {
  add: (value) => AxiosInterceptor().post('/addDealer', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllDealers`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneDealer/${values.id}`),
  updateDealer: (values) => AxiosInterceptor().post(`/updateDealer`, values),
  changeStatus: (values) => AxiosInterceptor().post(`/changeStatusDealer`, values),
  deleteDealer: (values) => AxiosInterceptor().get(`/deleteDealer/${values.id}`, ),
  approveDealer: (values) => AxiosInterceptor().post(`/approveDealer`, values),
}

const customerManagementApi = {
  add: (value) => AxiosInterceptor().post('/addUser', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllUsers`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneUser/${values.id}`),
  updateUser: (values) => AxiosInterceptor().post(`/updateUser`, values),
  changeStatus: (values) => AxiosInterceptor().post(`/changeStatusUser`, values),
  deleteUser: (values) => AxiosInterceptor().get(`/deleteUser/${values.id}`, ),
}

const cmsApi = {
  add: (value) => AxiosInterceptor().post('/addContent', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllContent`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneContent/${values.id}`, ),
  updateUser: (values) => AxiosInterceptor().post(`/updateContent`, values),
}


export default {categoryManagementApi, productManagementApi, dealerManagementApi, customerManagementApi, cmsApi};
