import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogTitle, FormControlLabel, FormGroup, IconButton, Input, InputAdornment, Switch, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { capitalize } from "../../utils/helpers";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Helmet } from "react-helmet-async";
import { STATUS_OPTIONS_LIST } from "../../utils/constants";
import TableFilter from "../../components/shared/TableFilter";
import ViewEmailDetail from "./View"
import FaqApi from "../../services/faqService";

export default function FaqList() {

  const navigate = useNavigate();
  const [searchItem, setSearchItem] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [warning, setWarning] = useState("");
  const [action, setAction] = useState("");
  const [show, setShow] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [statusId, setStatusId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [viewId, setViewId] = useState("");
  const handleClose = () => setShow(false);

  const columns = [
    {
      name: "S.No.",
      sortable: false,
      cell: (row, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      name: "Question",
      sortable: true,
      selector: (row) => capitalize(row.title),
    },   
    {
      name: "Status",
      cell: (row) => (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={row.status == 1}
                  onClick={() => {
                    setShow(true);
                    setCurrentStatus(row.status);
                    setStatusId(row._id);
                    setAction("status");
                    setWarning(
                      `Are you sure you want to ${
                        row.status === 1 ? "deactive" : "activate"
                      }?`
                    );
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </FormGroup>
        </>
      ),
    },  
    {
      name: "Action",
      right: true,
      cell: (row) => (
        <>
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/faq/edit/${row?._id}`);
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="View" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setModalShow(true);
                setViewId(row._id);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="right">
            <IconButton color="primary" onClick={() => deleteData(row?._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const fetchProductData = () => {
    const body = {
      searchItem: searchItem ? searchItem.trim() : "",
      pageNumber: currentPage,
      pageSize: pageSize,
      status: status,
    };

    FaqApi
      .getFaq(body)
      .then((res) => {
        if (res.status === 200) {
          setData(res?.data?.data?.result || []);
          setTotalRecords(res?.data?.data?.totalRecords || 0);
        }
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  useEffect(() => {
    fetchProductData();
  }, [searchItem, currentPage, pageSize, refresh, status]);

  const deleteData = async (id) => {
    Swal.fire({
      text: "Are you sure you want to delete this Question?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a1da",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await FaqApi.deleteFaq({ id });
          if (res?.status === 200) {
            toast.success(res?.data?.message, { toastId: "success" });
            const updatedData = data.filter((item) => item._id !== id);
            setData(updatedData);
            setTotalRecords((prevTotal) => prevTotal - 1);
  
            if (updatedData.length === 0 && currentPage > 1) {
              setCurrentPage((prevPage) => prevPage - 1);
            } else {
              // Refresh the data
              fetchProductData();
            }
          } else {
            toast.error(res?.data?.message, { toastId: "error" });
          }
        } catch (error) {
          errorHandler(error);
        }
      }
    });
  };
  

  const toggleChecked = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus ? 0 : 1;
      const res = await FaqApi.changeStatusFaq({ id, status: newStatus });
      if (res?.status === 200) {
        toast.success(res?.data?.message, { toastId: "success" });
        setRefresh(!refresh);
        setShow(false);
      } else {
        toast.error(res?.data?.message, { toastId: "error" });
        setShow(false);
      }
    } catch (err) {
      setShow(false);
      errorHandler(err);
    }
  };

  return (
    <Card>
      <Helmet>
        <title>FAQ Management</title>
      </Helmet>
      <CardHeader title="FAQ Management" titleTypographyProps={{ variant: "h4" }} />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <TableFilter data={STATUS_OPTIONS_LIST} value={status} setValue={setStatus} filterName="Status" />
        <Input
          placeholder="Search"
          value={searchItem}
          onChange={(e) => setSearchItem(e.target.value.trimStart())}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchItem("")}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          size="large"
          variant="contained"
          sx={{ ml: 3, mr: 3 }}
          onClick={() => navigate("/faq/add")}
        >
          Add
        </Button>
      </Box>
      <CardContent>
        <DataTable
         customStyles={{
          headCells: {
            style: {
              fontSize: "15px",
              fontWeight: "bold",
            },
          },
        }}
          columns={columns}
          data={data}
          pagination
          paginationServer
          paginationTotalRows={totalRecords}
          onChangePage={(page) => setCurrentPage(page)}

          paginationRowsPerPageOptions={[2,5, 10, 20, 50]}
          paginationPerPage={pageSize}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPageSize(currentRowsPerPage);
            setCurrentPage(1);
          }}
        />
      </CardContent>

      {modalShow ? (
        <ViewEmailDetail
          show={modalShow}
          onHide={() => setModalShow(false)}
          id={viewId}
        />
      ) : (
        ""
      )}
      <Dialog
        open={show}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle as="h2">{warning}</DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              if (action === "status") {
                toggleChecked(statusId, currentStatus);
              }
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>      
    </Card>
  );
}