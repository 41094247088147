import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { MESSAGE } from "../../utils/validationMessage";
import apiAuth from "../../services/authService";
import { PASSWORDS_REGEX } from "../../utils/constants";
import errorHandler from "../../utils/errorHandler";
import { IconButton, InputAdornment, Link as MLink, Stack, TextField, Typography } from "@mui/material";
import palette from "../../theme/palette";
import CardLayout from "../../layouts/CardLayout";
import Iconify from "../../components/iconify";

const validationSchema = Yup.object().shape({

  password: Yup.string()
    .required(MESSAGE.REQUIRED)
    .matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
  cpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(MESSAGE.REQUIRED),
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const { state } = useLocation()
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  const { otp } = useParams();

  const formik = useFormik({
    initialValues: { cpassword: "", password: "" },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      apiAuth
        .resetPassword({
          newPassword: values?.password,
          confirmPassword: values?.cpassword,          
        },otp)
        .then((res) => {
          toast.success(res?.data?.message);
          navigate("/dashboard");
        })
        .catch((err) => {
          errorHandler(err);
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Typography variant="h3" sx={{ mb: 2 }} align="center">
        Reset Password
      </Typography>
      <CardLayout>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>

            <TextField
              name="password"
              label="Password"
              placeholder="Password"
              focused={true}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword
                            ? "eva:eye-fill"
                            : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...getFieldProps("password")}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <TextField
              name="cpassword"
              label="Confirm Password"
              placeholder="Confirm Password"
              focused={true}
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showConfirmPassword
                            ? "eva:eye-fill"
                            : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...getFieldProps("cpassword")}
              error={Boolean(touched.cpassword && errors.cpassword)}
              helperText={touched.cpassword && errors.cpassword}
            />

          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ my: 2 }}
          >
            Change Password
          </LoadingButton>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ my: 2 }}
          >
            <MLink
              variant="subtitle2"
              underline="hover"
              to="/dashboard"
              as={Link}
              color={palette.primary.main}
            >
              Back to Dashboard
            </MLink>
          </Stack>
        </Form>
      </CardLayout>
    </FormikProvider>
  );
}