import { Grid, CardHeader, CardContent, Box, TextField, Button, Typography} from "@mui/material";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { Cancel, Delete } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import CardLayout from "../../layouts/CardLayout";
import ApiService from "../../services/categoryManagementApi";
import errorHandler from "../../utils/errorHandler";
import { IMAGE_BASE } from "../../utils/constants";
import { dealerSchema } from "../../utils/Schema";
const { dealerManagementApi } = ApiService;

function AddDealer() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const fileInputRef = useRef(null);
  const documentInputRef = useRef(null);
  const [data, setData] = useState(null);
  const [deletedDocuments, setDeletedDocuments] = useState([]);
  const [oldDocuments, setOldDocuments] = useState([]); 
  const [isDealerAdded, setIsDealerAdded] = useState(false);


  const fetchTaskData = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await dealerManagementApi.getById({ id });
        const resData = response?.data?.data;
        setSelectedDocuments(resData?.documents || []);
        setOldDocuments(resData?.documents || []);
        setImagePreview(IMAGE_BASE + resData?.image);
        setData(resData);
        const dealervalue = resData.dealerId;
        setIsDealerAdded(dealervalue);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      businessName: data?.businessName || "",
      email: data?.email || "",
      phoneNumber: data?.phoneNumber || "",
      imagePath: "dealer",
      documents: data?.documents || [],
      dealerId: data?.dealerId || '',
    },
    validationSchema: dealerSchema(id),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        if (id) formData.append("id", id);
        formData.append("businessName", values.businessName);
        formData.append("email", values.email);
        formData.append("phoneNumber", values.phoneNumber);
        formData.append("imagePath", "dealer");
        formData.append("dealerId", values.dealerId);
  
        // Get the new documents (uploaded files)
        const newDocuments = selectedDocuments.filter(doc =>
          !oldDocuments.some(oldDoc => oldDoc.name === doc.name && oldDoc.size === doc.size)
        );
  
        // Add new documents to form data
        if (newDocuments.length > 0) {
          newDocuments.forEach(doc => {
            formData.append("documents", doc);
          });
        }
  
        // Only remove documents that have been marked for deletion from oldDocuments
        const updatedOldDocuments = oldDocuments.filter(
          (doc) => !deletedDocuments.some(deletedDoc => deletedDoc === doc) // Compare by document name or path
        );
  
        formData.append("oldDocuments", JSON.stringify(updatedOldDocuments));
  
        // Add deleted documents to form data
        if (deletedDocuments.length > 0) {
          formData.append("deletedDocuments", JSON.stringify(deletedDocuments));
        }
  
        const response = id
          ? await dealerManagementApi.updateDealer(formData)
          : await dealerManagementApi.add(formData);
  
        toast.success(response?.data?.message, { toastId: "success" });
        navigate(-1);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
  });
  
const handleDocumentChange = (event) => {
  const files = Array.from(event.currentTarget.files);
  let newFiles = [];
  const invalidFiles = [];

  files.forEach((file) => {
    if (file.type !== "application/pdf") {
      invalidFiles.push(file.name); 
    } else if (!selectedDocuments.some((existingFile) => existingFile.name === file.name && existingFile.size === file.size)) {
      newFiles.push(file);
    }
  });

  if (invalidFiles.length > 0) {
    toast.error(`Only PDF allowed.`);
  }

  if (newFiles.length > 0) {
    newFiles.forEach((file) => {
      // Create a URL for the new file
      file.preview = URL.createObjectURL(file);
    });
    setSelectedDocuments((prevState) => [...prevState, ...newFiles]);
    formik.setFieldValue("documents", [...formik.values.documents, ...newFiles]);
  } else if (invalidFiles.length === 0) {
    toast.error("You cannot select the same file again.");
  }

  if (documentInputRef.current) {
    documentInputRef.current.value = ""; 
  }
};

 
const handleDeleteDocument = (index) => {
  const documentToDelete = selectedDocuments[index];
  
  // Mark the document as deleted
  setDeletedDocuments((prevState) => [...prevState, documentToDelete]);

  // Remove it from the selected documents
  const updatedDocuments = selectedDocuments.filter((_, i) => i !== index);
  setSelectedDocuments(updatedDocuments);
  
  formik.setFieldValue("documents", updatedDocuments);

  if (documentInputRef.current) {
    documentInputRef.current.value = "";
  }
};

  
  return (
    <Box>
      <Helmet>
        <title>{id ? "Edit" : "Add"} Dealer</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`${id ? "Edit" : "Add"} Dealer`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6} mt={2}>
                  <TextField
                    fullWidth
                    label="Name"
                    required
                    value={formik.values.businessName}
                    onChange={formik.handleChange}
                    name="businessName"
                    error={Boolean(formik.touched.businessName && formik.errors.businessName)}
                    helperText={formik.touched.businessName && formik.errors.businessName}
                  />
                </Grid>
                <Grid item xs={6} mt={2}>
                  <TextField
                    fullWidth
                    label="Email"
                    required
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name="email"
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={6} mt={2}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    required
                    type="text"
                    value={formik.values.phoneNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only numbers and limit to 10 digits
                      if (/^\d{0,10}$/.test(value)) {
                        formik.setFieldValue("phoneNumber", value);
                      }
                    }}
                    name="phoneNumber"
                    error={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  />
                </Grid>
                {!id ?
                <Grid item xs={6} mt={2}>
                  <TextField
                    fullWidth
                    label="Dealer Id"
                    required
                    disabled={isDealerAdded} 
                    value={formik.values.dealerId}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,6}$/.test(value)) {
                        formik.setFieldValue("dealerId", value);
                      }
                    }}
                    type="text"
                    name="dealerId"
                    error={Boolean(formik.touched.dealerId && formik.errors.dealerId)}
                    helperText={formik.touched.dealerId && formik.errors.dealerId}                   
                  />
                </Grid>
                :null}
                <Grid item xs={12} mt={2}>
                <Box display="flex" alignItems="center">
                  <Button
                    variant="outlined"
                    component="label"
                  >
                    Upload Documents
                    <input
                      type="file"
                      hidden
                      multiple
                      accept="application/pdf"
                      onChange={handleDocumentChange}
                      ref={documentInputRef}
                    />
                  </Button>
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {selectedDocuments.length > 0
                      ? `${selectedDocuments.length} File${selectedDocuments.length > 1 ? "s" : ""} Selected`
                      : "No file selected"}
                  </Typography>
                </Box>

                {/* Document Preview Section */}
                {selectedDocuments.length > 0 && (
                  <Box mt={2}>
                    {selectedDocuments.map((document, index) => (
                      <Box key={index} position="relative" display="inline-block" mr={2} mb={2}>
                        <Link
                          to={document.preview || IMAGE_BASE + document}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/assets/pdf.png"
                            alt="PDF Preview"
                            width={80}
                            height={80}
                          />
                        </Link>
                        <Button
                          variant="text"
                          color="error"
                          className="deletepdficon"
                          onClick={() => handleDeleteDocument(index)}
                          startIcon={<Cancel />}
                          sx={{
                            position: "absolute",
                            left: "49px",
                            top: "-15px",
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            borderRadius: "50%",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                )}
              </Grid>
           
                <Grid item xs={12} mt={4}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    {id ? "Edit" : "Create"} Dealer
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default AddDealer;