import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography} from "@mui/material";
import { useEffect, useState } from "react";
import FaqApi from "../../services/faqService";
import errorHandler from "../../utils/errorHandler";
import { Loader } from "../../components/CustomLoader";
import { capitalize } from "../../utils/helpers";


const ViewFaqDetail = ({ id, show, onHide }) => {
const [data, setData] = useState("");
const [loading, setLoading] = useState(true)

const userData = async () => {
    try {
    setLoading(true)
    const response = await FaqApi.getByIdFaq({id:id});
    setData(response.data.data);
    } catch (error) {
    errorHandler(error)
    } finally {
    setLoading(false)
    }
};

useEffect(() => {
    userData();
}, []);

return (
    <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
    <DialogTitle as="h2">Details</DialogTitle>
    {loading ? <Loader /> :
        <DialogContent dividers>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
            <Typography variant="subtitle1">Question</Typography>
            <DialogContentText sx={{ marginBlockStart: "1em" }}>
                {capitalize(data?.title)}{" "}
            </DialogContentText>
            </Grid>
            <Grid item xs={12} md={12}>
            <Typography variant="subtitle1">Answer</Typography>
            <DialogContentText>
                <Typography
                dangerouslySetInnerHTML={{ __html: data.description }}
                />
            </DialogContentText>
            </Grid>
        </Grid>
        <DialogActions>
            <Button
                variant="outlined"
                onClick={() => onHide()}
                type="submit"
            >
             Close
            </Button>
        </DialogActions>
        </DialogContent>
    }
    </Dialog>
);
};
  
export default ViewFaqDetail;
  