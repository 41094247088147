import { Typography } from '@mui/material';
import React from 'react'

function StatusBTN({status,sx}) {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        border: status ? "2px solid #28a745" : "2px solid #dc3545",
        color: "#fff",
        borderRadius: "6px",
        // width: "min-content",
        padding: "2px 10px",
        background: status ? "#28a745" : "#dc3545",
        ...sx,
      }}
    >
      {status ? "Active" : "Inactive"}
    </Typography>
  );
}

export default StatusBTN