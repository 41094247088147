import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Link as MLink, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { MESSAGE } from "../../utils/validationMessage";
import palette from "../../theme/palette";
import apiAuth from "../../services/authService";
import { NOSPACE_REGEX } from "../../utils/constants";
import { complexEmailRegex } from "../../utils/emailCheck";
import errorHandler from "../../utils/errorHandler";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import Swal from "sweetalert2";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(MESSAGE.REQUIRED)
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
    .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val))
    .max(255),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  textAlign: "center", 
};
export default function Login() {
  const navigate = useNavigate();
  // const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
 
   const handleClose = () => {
     setOpen(false);
   };
   const handleOK= () => {
      navigate("/login");

   };
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      apiAuth
        .forgot(values)
        .then((res) => {
          toast.success(res?.data?.message);
          Swal.fire({
            title: "Reset Password",
            text: " A reset email has been sent to your registered Email ID.",
            icon: "info",
            showCancelButton: false,
            confirmButtonColor: "#00a1dd",
            confirmButtonText: "Ok",
          }).then(async (result) => {
            if (result.isConfirmed) {
              navigate("/login");
            }
          });
         
        })
        .catch((err) => {
          errorHandler(err);
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Typography variant="h3" sx={{ mb: 2 }} align="center">
        Forget Password
      </Typography>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email address"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ my: 2 }}
        >
          Reset Password
        </LoadingButton>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ my: 2 }}
        >
          <MLink
            variant="subtitle2"
            underline="hover"
            to="/login"
            as={Link}
            color={palette.primary.main}
          >
            Back to Login
          </MLink>
        </Stack>
      </Form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">Reset Password</h2>
          <p id="parent-modal-description">
            A reset email has been sent to your registered Email ID.
          </p>
          <Button onClick={handleOK}>OK</Button>
        </Box>
      </Modal>
    </FormikProvider>
  );
}
