import AxiosInterceptor from "../utils/AxiosInterceptor";

const FaqApi = {
  addFaq: (value) => AxiosInterceptor().post(`/addFaqs`, value),
  editFaq: (value) => AxiosInterceptor().post(`/updateFaqs`, value),
  deleteFaq: (value) => AxiosInterceptor().get(`/deleteFaq/${value.id}`),
  getFaq: (values) => AxiosInterceptor().post(`/getAllFaqs`, values),
  getByIdFaq: (values) => AxiosInterceptor().get(`/getOneFaq/${values.id}`),

  changeStatusFaq: (values) => AxiosInterceptor().post(`/changeStatusFaq`, values),
};

export default FaqApi;
