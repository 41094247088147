import AxiosInterceptor from "../utils/AxiosInterceptor";

const apiOfferBannerManagement = {
  add: (value) => AxiosInterceptor().post(`/addBanner`, value),
  getAll: (values) => AxiosInterceptor().post(`/getAllBanners`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneBanner/${values.id}`),
  changeStatus: (values) => AxiosInterceptor().post(`/changeStatusBanner`, values),
  deleteBanner: (values) => AxiosInterceptor().get(`/deleteBanner/${values.id}`, ),
  documentVerification: (values) => AxiosInterceptor().post(`/creator-verify`, values),
  getProductList: () => AxiosInterceptor().get(`/getProductList`, ),
  updateBanner: (values) => AxiosInterceptor().post(`/updateBanner`, values),
};

export default apiOfferBannerManagement;
