import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { capitalize } from "../../utils/helpers";
import { EditOutlined } from "@mui/icons-material";
import errorHandler from "../../utils/errorHandler";
import CustomLoader from "../../components/CustomLoader";
import ApiService from "../../services/categoryManagementApi";
import { fDate } from "../../utils/formatTime";
const { cmsApi } = ApiService;

export default function CmsPage() {
  const navigate = useNavigate();
  const [paginData, setPaginData] = useState({
    totalCount: 0,
    data: [],
  });

  const [payload, setPayload] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      name: "Serial No.",
      selector: (row, index) => {
        return (payload.pageNumber - 1) * payload.pageSize + index + 1;
      },
      sortable: false,
    },
    {
      name: "Title",
      sortField: "title",
      sortable: true,
      selector: (row) => capitalize(row.title),
    },

    {
      name: "Slug",
      sortable: true,
      sortField: "slug",
      selector: (row) => row.contentType,
    },
    {
      name: "Short description",
      sortField: "description",
      sortable: true,
      selector: (row) => {
        const plainText = row.description.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ").trim();
        return plainText.length > 100 ? `${plainText.substring(0, 100)}...` : plainText;
      },
      width: "250px",
    },  
    {
      name: "Created At",
      sortable: true,
      selector: (row) => capitalize(fDate(row.createdAt)),      
    },
    {
      name: "Action",
      right: true,
      cell: (row) => (
        <>
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                console.log("row",row);
                navigate(`/cms/edit/${row._id}`, { state: { rowData: row } });
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip title="View" placement="top" leaveDelay={50}>
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/cms/view/${row._id}`, { state: { rowData: row } });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const fetchCMSList = async () => {
    try {
      setLoading(true);
      let res = await cmsApi.getAll(payload);
      console.log(res?.data?.data);
      setPaginData({
        data: res?.data?.data?.result,
        totalCount: res?.data?.data?.totalRecords,
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCMSList();
  }, [payload]);

  const handleSort = (column, sortDirection) => {
    const srt = {
      [column.sortField]: sortDirection === "asc" ? -1 : 1,
    };
    setPayload((prev) => ({
      ...prev,
      sortObject: srt,
    }));
  };
  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: "h4" }}
        title="CMS Management"
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
      </Box>

      <CardContent>
        
        <DataTable
          customStyles={{
            headCells: {
              style: {
                fontSize: "15px",
                fontWeight: "bold",
                width:"auto"
              },
            },
          }}
          columns={columns}
          data={paginData?.data}
          pagination
          paginationServer
          paginationTotalRows={paginData?.totalCount}
          onChangePage={(page) => setPayload({ ...payload, pageNumber: page })}
          paginationPerPage={payload.pageSize}
          paginationRowsPerPageOptions={[5, 10, 20, 50]}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
            setPayload({
              ...payload,
              pageSize: currentRowsPerPage,
              pageNumber: currentPage,
            });
          }}
          progressPending={loading}
          onSort={handleSort}
          progressComponent={<CustomLoader />}
        />
      </CardContent>
    </Card>
  );
}
