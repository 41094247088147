import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import GlobalSetting from "../pages/Global Setting";
import AddBannerOffer from "../pages/Offerandbannermanagement/Add";
import Offerandbannermanagement from "../pages/Offerandbannermanagement/List";
import OfferAndBannerView from "../pages/Offerandbannermanagement/View";
import { element } from "prop-types";
import CategoryManagement from "../pages/CategoryManagement/List";
import AddCategory from "../pages/CategoryManagement/Add";
import CategoryView from "../pages/CategoryManagement/View";
import ProductManagement from "../pages/ProductManagement/List";
import AddProduct from "../pages/ProductManagement/Add";
import ProductView from "../pages/ProductManagement/View";
import AddDealer from "../pages/DealerManagement/Add";
import DealerView from "../pages/DealerManagement/View";
import DealerManagement from "../pages/DealerManagement/List";
import CustomerManagement from "../pages/CustomerManagement/List";
import AddCustomer from "../pages/CustomerManagement/Add";
import CustomerView from "../pages/CustomerManagement/View";
import CmsPage from "../pages/cms/List";
import AddEditCms from "../pages/cms/Add";
import ViewCms from "../pages/cms/View";
import FaqList from "../pages/faq/List";
import AddEditFaq from "../pages/faq/Edit";
import ViewFaqDetail from "../pages/faq/View";

const ChangePassword = lazy(() => import("../pages/auth/ChangePassword"));

//CMS............................................................ ............................
// const CmsPage = lazy(() => import("../pages/cms/List"));
// const ViewCms = lazy(() => import("../pages/cms/View"));
// const AddEditCms = lazy(() => import("../pages/cms/Add"));

//MyAccount........................................................................................
const MyAccount = lazy(() => import("../pages/myAccount/MyAccount"));

const PrivateLayout = lazy(() => import("../layouts/private"));
const Page404 = lazy(() => import("../pages/error/Page404"));
const DashboardPage = lazy(() => import("../pages/dashboard/DashboardPage"));
const LoadingFallback = <div>Loading...</div>;

const makeLazyComponent = (importFunc) => (
  <Suspense fallback={LoadingFallback}>{importFunc()}</Suspense>
);

const MainRouting = () => {
  const routes = [
    {
      element: <PrivateLayout />,
      errorElement: <Page404 />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        {
          path: "dashboard",
          element: makeLazyComponent(() => <DashboardPage />),
        },

        {
          path: "reset-password",
          element: makeLazyComponent(() => <ChangePassword />),
        },
        {
          path: "profile",
          element: makeLazyComponent(() => <MyAccount />),
        },
        {
          path: "settings",
          element: makeLazyComponent(() => <GlobalSetting />),
        },
        {
          path: "offer-and-banner-management",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <Offerandbannermanagement />),
            },
            { path: "add", element: makeLazyComponent(() => <AddBannerOffer />) },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <OfferAndBannerView />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <AddBannerOffer />),
            },
          ],
        },
        {
          path: "category-management",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <CategoryManagement />)
            },
            { path: "add", element: makeLazyComponent(() => <AddCategory />)},
            { path: "view/:id", element: makeLazyComponent(() => <CategoryView />)},
            {path: "edit/:id", element: makeLazyComponent(() => <AddCategory />)}
          ]

        },
        {
          path: "product-management",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ProductManagement />)
            },
            { path: "add", element: makeLazyComponent(() => <AddProduct />)},
            { path: "view/:id", element: makeLazyComponent(() => <ProductView />)},
            {path: "edit/:id", element: makeLazyComponent(() => <AddProduct />)}
          ]
        },
        {
          path: "dealer-management",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <DealerManagement />)
            },
            { path: "add", element: makeLazyComponent(() => <AddDealer />)},
            { path: "view/:id", element: makeLazyComponent(() => <DealerView />)},
            {path: "edit/:id", element: makeLazyComponent(() => <AddDealer />)}
          ]
        },
        {
          path: "customer-management",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <CustomerManagement />)
            },
            { path: "add", element: makeLazyComponent(() => <AddCustomer />)},
            { path: "view/:id", element: makeLazyComponent(() => <CustomerView />)},
            {path: "edit/:id", element: makeLazyComponent(() => <AddCustomer />)}
          ]
        },
        // {
        //   path: "product-management",
        //   children: [
        //     {
        //       index: true,
        //       element: makeLazyComponent(() => <ProductManagement />)
        //     },
        //     { path: "add", element: makeLazyComponent(() => <AddProduct />)},
        //     { path: "view/:id", element: makeLazyComponent(() => <ProductView />)},
        //     {path: "edit/:id", element: makeLazyComponent(() => <AddProduct />)}
        //   ]
        // },
        {
          path: "cms",
          children: [
            { index: true, element: makeLazyComponent(() => <CmsPage />),},
            { path: "add", element: makeLazyComponent(() => <AddEditCms />), },
            { path: "edit/:id", element: makeLazyComponent(() => <AddEditCms />), },
            { path: "view/:id", element: makeLazyComponent(() => <ViewCms />), },
          ],
        },
        {
          path: "faq",
          children: [
            { index: true, element: makeLazyComponent(() => <FaqList /> )},
            { path: "add", element: makeLazyComponent(() => <AddEditFaq />) },
            { path: "edit/:id", element: makeLazyComponent(() => <AddEditFaq />) },
            { path: "view/:id", element: makeLazyComponent(() => <ViewFaqDetail />), },
          ]
        }
      ],
    },
    { path: "*", element: <Navigate to="/dashboard" /> },
  ];

  return useRoutes(routes);
};

export default MainRouting;