import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Typography,
  CardHeader,
  CardContent,
  Stack,
  Button,
  Box,
  Divider,
  Avatar,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import { IMAGE_BASE } from "../../utils/constants";
import ApiService from "../../services/categoryManagementApi";

const { customerManagementApi } = ApiService;

const CustomerView = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customerManagementApi.getById({ id: id });
        setData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Box>
      <Helmet>
        <title>Customer Management</title>
      </Helmet>
      <CardLayout>
        <CardHeader title="Customer View" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                <CardContent>
                  <Stack spacing={2}>
                    <Typography variant="h6">Name</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.name || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">ID No./Registration No.</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.registrationNumber || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Phone Number</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.phoneNumber || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Email</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.email || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Address</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.flatNumber + ", " + data?.area + ", " + data?.locality + ", " + data?.landmark + ", " + data?.city + ", " + data?.village + ", " + data?.state + ", " + data?.pinCode || ""}
                    </Typography>
                    <Divider></Divider>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

export default CustomerView;
