import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardContent, FormHelperText, Grid, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { NOSPACE_REGEX } from '../../utils/constants';
import errorHandler from '../../utils/errorHandler';
import EdiorJodit from '../../components/editor/editor';
import FaqApi from '../../services/faqService';
import { validationFaqSchema } from '../../utils/Schema';

export default function AddEditFaq() {
  const navigate = useNavigate();
  const [cmsData, setCmsData] = useState({ title: '', description: '' });
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      FaqApi.getByIdFaq({ id })
        .then((res) => {
          const data = res?.data?.data;
          formik.setValues({
            title: data?.title || '',
            description: data?.description || '',
            status: typeof data?.status === 'number' ? data.status : 1, 
          });
          setCmsData({
            title: data?.title || '',
            description: data?.description || '',
          });
        })
        .catch((err) => {
          errorHandler(err);
        });
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      title: cmsData.title || '',
      description: cmsData.description || '',
      status: cmsData?.status || 1,
    },
    validationSchema:validationFaqSchema,
    onSubmit: (values, { setSubmitting }) => {
      const apiCall = id
        ? FaqApi.editFaq({
            id,
            title: values.title,
            description: cmsData.description,
            status: values.status,
          })
        : FaqApi.addFaq({
            title: values.title,
            description: cmsData.description,
            status: values.status,
            contentType: "faq",
          });

      apiCall
        .then((res) => {
          toast.success(res?.data?.message);
          navigate("/faq");
          setSubmitting(false);
        })
        .catch((err) => {
          errorHandler(err);
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Card>
      <CardContent>
        <FormikProvider value={formik}>
          <Typography variant="h4" sx={{ mb: 2 }} align="start">
            {id ? "Edit" : "Add"} FAQ
          </Typography>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                name="title"
                label="Question"
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
              <EdiorJodit
                onChange={(html) => (
                  formik.setFieldValue("description", html),
                  setCmsData({ ...cmsData, description: html }))
                }
                data={id ? cmsData.description :"<p><br/></p>"}
                placeholder="Answer"
              />
               {formik.touched.description && formik.errors.description && (
                    <FormHelperText error>
                      {formik.errors.description}
                    </FormHelperText>
                  )}

           <Grid item xs={12}>
              <Select
                labelId="status-label"
                id="status"
                value={formik.values.status}
                onChange={(e) => formik.setFieldValue("status", e.target.value)}
                fullWidth
                error={Boolean(touched.status && errors.status)}
                displayEmpty
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={0}>Inactive</MenuItem>
              </Select>
              {touched.status && errors.status && (
                <FormHelperText error>{errors.status}</FormHelperText>
              )}
            </Grid>

            </Stack>
            <Stack
              spacing={2}
              mt={3}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                size="large"
                type="submit"
                variant="contained"
                disabled={isSubmitting}
              >
                Save
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={() => !isSubmitting && navigate("/faq")}
                sx={{ ml: 2 }}
              >
                Cancel
              </Button>
            </Stack>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  );
}
