import { Avatar, Box, Button, CircularProgress, Grid } from "@mui/material";

export const handleNumberInput = (e, formikname, formik, allowDecimal, limit) => {
    const re = allowDecimal ? new RegExp(`^\\d*\\.?\\d{0,${limit}}$`) : new RegExp(`^[0-9]\\d{0,${limit - 1}}$`);
    if (e.target.value === '' || re.test(e.target.value)) {
        formik.setFieldValue(formikname, e.target.value);
    } else {
        return false;
    }
}
