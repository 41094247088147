import { Button, CircularProgress } from '@mui/material'
import { Icon } from "@iconify/react";
function LoadingButton({ text, loading }) {
    return (
        <Button
            type="submit"
            variant="contained"
            startIcon={<Icon icon="fa-solid:save" />}
        >
            {loading ? <CircularProgress sx={{ color: "#fff", width: 10, height: 10 }} /> : text}
        </Button>
    )
}

export default LoadingButton