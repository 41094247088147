import React, { useEffect, useState } from "react";
import { 
  Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogTitle, 
  FormControlLabel, FormGroup, IconButton, Input, InputAdornment, Switch, Tooltip 
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { capitalize } from "../../utils/helpers";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { EditOutlined, VerifiedTwoTone } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Helmet } from "react-helmet-async";
import ApiService from "../../services/categoryManagementApi";
import { fDateTime } from "../../utils/formatTime";
import TableFilter from "../../components/shared/TableFilter";
import { STATUS_OPTIONS_LIST } from "../../utils/constants";

const { dealerManagementApi } = ApiService;

export default function DealerManagement() {
  const navigate = useNavigate();

  // State variables
  const [searchItem, setSearchItem] = useState("");
  const [totalPages, setTotalPages] = useState(0); // Total records from the API
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [pageSize, setPageSize] = useState(10); // Number of records per page
  const [data, setData] = useState([]); // Dealer data
  const [warning, setWarning] = useState("");
  const [action, setAction] = useState("");
  const [show, setShow] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [statusId, setStatusId] = useState("");
  const [refresh, setRefresh] = useState(false); // Trigger data refresh
  const [status, setStatus] = useState([]);
  const [verifyDialog, setVerifyDialog] = useState(false);
  const [verifyId, setVerifyId] = useState("");
  const [verifyIdError, setVerifyIdError] = useState("");

  // Dialog handlers
  const handleClose = () => setShow(false);
  const handleVerifyClose = () => setVerifyDialog(false);

  // Table column definitions
  const columns = [
    {
      name: "S.No.",
      sortable: false,
      cell: (row, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      name: "Name",
      sortable: true,
      selector: (row) => capitalize(row.businessName),
    },
    {
      name: "Email",
      sortable: true,
      selector: (row) => capitalize(row.email),
    },
    {
      name: "Dealer ID",
      sortable: true,
      selector: (row) => capitalize(row.dealerId),
    },
    {
      name: "Date",
      sortable: true,
      selector: (row) => fDateTime(row.createdAt),
    },
    {
      name: "Status",
      cell: (row) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={row.status === 1}
                onClick={() => {
                  setShow(true);
                  setCurrentStatus(row.status);
                  setStatusId(row._id);
                  setAction("status");
                  setWarning(
                    `Are you sure you want to ${
                      row.status === 1 ? "deactivate" : "activate"
                    } the dealer?`
                  );
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        </FormGroup>
      ),
    },
    {
      name: "Action",
      right: true,
      cell: (row) => (
        <>
          {row.approved === false && row.documents.length > 0  ? (
            <Tooltip title="Approve" placement="right">
              <IconButton
                color="primary"
                onClick={() => {
                  setVerifyDialog(true);
                  setStatusId(row._id);
                }}
              >
                <VerifiedTwoTone />
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title="View" placement="top">
            <IconButton
              color="primary"
              onClick={() => navigate(`/dealer-management/view/${row?._id}`)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => navigate(`/dealer-management/edit/${row?._id}`)}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="right">
            <IconButton
              color="primary"
              onClick={() => deleteData(row?._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  // Fetch dealer data
  useEffect(() => {
    const body = {
      searchItem: searchItem || "",
      pageNumber: currentPage,
      pageSize: pageSize,
      status: status,
    };

    dealerManagementApi
      .getAll(body)
      .then((res) => {
        if (res.status === 200) {
          setData(res?.data?.data?.result);
          setTotalPages(res?.data?.data?.totalRecords);
        }
      })
      .catch((err) => errorHandler(err));
  }, [searchItem, currentPage, pageSize, refresh, status]);

  const handlePageChange = (page) => setCurrentPage(page);

  const deleteData = async (id) => {
    Swal.fire({
      text: "Are you sure you want to delete the dealer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a1da",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await dealerManagementApi.deleteDealer({ id });
          if (res?.status === 200) {
            toast.success(res?.data?.message);
            setRefresh(!refresh);
          } else {
            toast.error(res?.data?.message);
          }
        } catch (error) {
          errorHandler(error);
        }
      }
    });
  };

  const handleVerifySubmit = async () => {
    if (verifyId.length < 6) {
      setVerifyIdError("Please enter a 6-digit dealer ID.");
      return;
    }
    try {
      const payload = { id: statusId, dealerId: verifyId };
      const response = await dealerManagementApi.approveDealer(payload);
      toast.success(response?.data?.message);
      setVerifyDialog(false);
      setRefresh(!refresh);
    } catch (error) {
      errorHandler(error);
    }
  };

  const toggleChecked = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus ? 0 : 1; 

      const res = await dealerManagementApi.changeStatus({
        id,
        status: newStatus,
      });

      setCurrentStatus("");

      if (res.status === 200 && res?.data?.message) {
        toast.success(res?.data?.message, { toastId: "error1" });
        setRefresh(!refresh);
        setShow(false);
      } else {
        toast.error(res?.data?.message, { toastId: "error1" });
        setShow(false);
      }
    } catch (err) {
      setShow(false);
      errorHandler(err);
    }
  };

  return (
    <Card>
      <Helmet>
        <title>Dealer Management</title>
      </Helmet>
      <CardHeader title="Dealer Management" />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <TableFilter
          data={STATUS_OPTIONS_LIST}
          value={status}
          setValue={setStatus}
          filterName="Status"
        />
        <Input
          value={searchItem}
          placeholder="Search"
          onChange={(e) => setSearchItem(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchItem("")}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          variant="contained"
          onClick={() => navigate("/dealer-management/add")}
        >
          Add
        </Button>
      </Box>
      <CardContent>
        <DataTable
          columns={columns}
          data={data || []}
          pagination
          paginationServer
          paginationTotalRows={totalPages}
          onChangePage={handlePageChange}
          paginationPerPage={pageSize}
          paginationRowsPerPageOptions={[2,5, 10, 20, 50]}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPageSize(currentRowsPerPage);
            setCurrentPage(1); // Reset to first page
          }}
        />
      </CardContent>


      <Dialog
        open={show}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle as="h2">{warning}</DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              if (action === "status") {
                toggleChecked(statusId, currentStatus); // Pass the id and current status
              }
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Verify Dialog */}
      <Dialog open={verifyDialog} onClose={handleVerifyClose}>
        <CardHeader title="Dealer ID" titleTypographyProps={{ align: "center", variant: "h6" }} />
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 3,
          }}
        >
          <Input
            placeholder="Dealer ID"
            value={verifyId}
            onChange={(e) => {
              const newValue = e.target.value.replace(/[^0-9]/g, "").slice(0, 6);
              setVerifyId(newValue);
              setVerifyIdError("");
            }}
            fullWidth
            sx={{
              marginBottom: 2,
              padding: "12px",
              maxWidth: 300,
            }}
            inputProps={{
              maxLength: 6,
            }}
          />
          {verifyIdError && (
            <Box sx={{ color: "red", fontSize: "14px", marginBottom: 2 }}>
              {verifyIdError}
            </Box>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={handleVerifySubmit}
            sx={{
              padding: "10px 20px",
              minWidth: 150,
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
