import { CircularProgress } from '@mui/material'
import React from 'react'

const CustomLoader = () => {
    return (
        <div className="pageloaderWrp">
            <div className="pageLoadr ">
                <CircularProgress />
            </div>
        </div>
    )
}


const Loader = () => {
    return (
        <div className="pageloaderWrp">
            <div className="pageLoadr custom_loader ">
                <CircularProgress />
            </div>
        </div>
    )
}

export default CustomLoader
export { Loader }