import React, { useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import { toast } from "react-toastify";
import { Box, CardHeader, CardContent,  Grid, FormHelperText, Stack, Select, MenuItem, TextField} from "@mui/material";
import { removeSpaces } from "../../utils/helpers";
import EditorJodit from "../../components/editor/editor";
import LoadingButton from "../../components/Buttons/LoadingButton";
import { CmsSchema } from "../../utils/Schema";
import ApiService from "../../services/categoryManagementApi";

const { cmsApi } = ApiService;

const AddEditCms = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const { id } = useParams();
  const pageType = id ? "Update" : "Add";
  const [cmsData, setCmsData] = useState("");
  const slug = state?.rowData?.slug


  useEffect(() => {
    if (!id) return;
    const fetchData = async () => {
      try {
        const { data } = await cmsApi.getById({ id });
        setCmsData(data.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: cmsData?.title || "",
      slug: cmsData?.slug || "cms-list",
      description: cmsData?.description || "",
      status: cmsData?.status || 1,
      contentType: cmsData?.contentType ||"",
    },
    validationSchema: CmsSchema,
    onSubmit: handleSubmit,
  });

  async function handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    try {
      values = removeSpaces(values);
      const valueData = {
        title: values.title,
        description: values.description,
        status: values.status,
      };

      if (id) valueData["id"] = id;
      const resp = await cmsApi.updateUser(valueData);
      // const resp = await cmsApi[pageType.toLowerCase()](valueData);
      if (resp.data.status) {
        toast.success(resp.data.message);
        navigate(-1);
      } else {
        toast.error(resp.data.message);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Box>
      <Helmet>
        <title>{pageType} CMS</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`${pageType} CMS`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={3} direction="column">
                <FormField name="title" label="Title" formik={formik} />
                <FormField name="slug" label="Slug" formik={formik} disabled={true} />
                <Grid item xs={12}>
                  <label>Description</label>
                  <EditorJodit
                    formik={formik}
                    fieldName="description"
                    onChange={(val) => formik.setFieldValue("description", val)}
                    data={formik.values.description}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <FormHelperText error>
                      {formik.errors.description}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Select
                    labelId="status-label"
                    id="status"
                    value={formik.values.status}
                    onChange={(e) =>
                      formik.setFieldValue("status", e.target.value)
                    }
                    fullWidth
                    error={Boolean(
                      formik.touched.status && formik.errors.status
                    )}
                    displayEmpty
                    defaultValue=""
                  >
                    <MenuItem value="">Select Status</MenuItem>
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={0}>Deactivate</MenuItem>
                  </Select>
                  {formik.touched.status && formik.errors.status && (
                    <FormHelperText error>
                      {formik.errors.status}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Stack
                spacing={2}
                mt={3}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <LoadingButton text={pageType} loading={formik.isSubmitting} />
              </Stack>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

const FormField = ({ name, label, formik, disabled = false }) => (
  <Grid item xs={12}>
    <TextField
      fullWidth
      name={name}
      label={label}
      value={formik.values[name]}
      onChange={formik.handleChange}
      required
      disabled={disabled}
      error={Boolean(formik.touched[name] && formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
    />
  </Grid>
);



export default AddEditCms;
