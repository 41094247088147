import {
  Grid,
  CardHeader,
  CardContent,
  Box,
  TextField,
  CircularProgress,
  Avatar,
  Button,
} from "@mui/material";
import apiOfferbannerManagement from "../../services/offerandbannerservices";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import errorHandler from "../../utils/errorHandler";
import { IMAGE_BASE } from "../../utils/constants";
import LoadingButton from "@mui/lab/LoadingButton";
import CardLayout from "../../layouts/CardLayout";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { category } from "../../utils/Schema";
import ApiService from "../../services/categoryManagementApi";

const { categoryManagementApi } = ApiService;

function AddCategory() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [products, setProducts] = useState([]);
  const fileInputRef = useRef(null);
  const [productList, setProductList] = useState([]);
  const [freeProductList, setFreeProductList] = useState([]);
  const [data, setData] = useState();

  const { id } = useParams();

  const fetchTaskData = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await categoryManagementApi.getById({ id });
        const resData = response?.data?.data;
        console.log("res",resData);
        setImagePreview(IMAGE_BASE + resData?.image);
        setFreeProductList([resData?.freeProductId?._id]);

        resData?.purchaseProductIds?.forEach((product) => {
          setProductList((prevProductList) => [
            ...prevProductList,
            product?._id,
          ]);
        });

        setData(resData);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || "",
      imagePath: "category",
      image: data?.image || "",
    },
    validationSchema: category,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        if (id) {
          formData.append("id", id);
        }
        formData.append("name", values?.name);
        formData.append("imagePath", "category");
        if (selectedImage) formData.append("image", selectedImage);

        const response = id
          ? await categoryManagementApi.updateCategory(formData)
          : await categoryManagementApi.add(formData, values);

        toast.success(response?.data?.message, { toastId: "success" });
        navigate(-1);

        fileInputRef.current.value = "";
        setSelectedImage(null);
        setImagePreview(null);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    const validTypes = ["image/jpeg", "image/png", "image/gif"];

    if (selectedImage && selectedImage.name === file?.name) {
      toast.error("You cannot upload the same image again.");
      fileInputRef.current.value = "";
      return;
    }

    if (file && validTypes.includes(file.type)) {
      setImageLoading(true);
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
      formik.setFieldValue("image", file);
      setTimeout(() => {
        setImageLoading(false);
      }, 2000);
    } else {
      toast.error("Please upload a valid image (JPEG, PNG, or GIF).");
      fileInputRef.current.value = "";
    }
  };


  return (
    <Box>
      <Helmet>
        <title>{id ? "Edit" : "Add"} Category</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`${id ? "Edit" : "Add"} Category`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
              <Grid item xs={12}>
                  {/* Hidden File Input */}
                  <input
                    accept="image/*"
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />

                  {/* Image Preview and Clickable Upload Box */}
                  <Box
                    mt={2}
                    onClick={() => fileInputRef.current.click()}
                    className="upload-box"
                  >
                    {imageLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Avatar
                        src={imagePreview || "/path/to/default-avatar.jpg"}
                        alt="preview"
                        className="avatar"
                      />
                    )}
                  </Box>

                  {/* Centered Upload Button */}
                  <Box mt={2} display="flex" justifyContent="center">
                  <Button
                        size="large"
                        variant="contained"
                        sx={{ ml: 3, mr: 3 }}
                        onClick={() => fileInputRef.current.click()}
                      >
                          {id ? "Edit" : imagePreview ? "Change" : "Upload"} Image
                      </Button>
                
                  </Box>

                  {/* Error Message */}
                  {formik.touched.image && formik.errors.image ? (
                    <div className="imageerror">
                      {formik.touched.image && formik.errors.image}
                    </div>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    required
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    name="name"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>

                <Grid item xs={12} justifyContent="center"  display="flex">
                <Grid item xs={6} justifyContent="center"  display="flex">
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    {id ? "Edit" : "Add"} Category
                  </LoadingButton>
                  </Grid> 
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default AddCategory;
