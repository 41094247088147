import React, { useEffect, useState } from "react";
import { Grid, Card, Typography, CardHeader, CardContent, Stack, Button, Box, Divider, Avatar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import { Icon } from "@iconify/react";
import StatusBTN from "../../components/StatusBTN";
import apiOfferBannerManagement from "../../services/offerandbannerservices";
import { IMAGE_BASE } from "../../utils/constants";
import { fDateTime } from "../../utils/formatTime";
import { capitalizeFirstLetter } from "../../utils/helpers";

const OfferAndBannerView = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiOfferBannerManagement.getById({ id: id });
        setData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  const handleEdit = () => {
    navigate(`/offer-and-banner-management/edit/${id}`);
  };

  
  const handleback = () => {
    navigate(`/offer-and-banner-management/`);
  };
  return (
    <Box>
      <Helmet>
        <title>Offer And Banner Management</title>
      </Helmet>
      <CardLayout>
        <CardHeader title="Offer And Banner View" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>                
                <CardContent>
                <Box display="flex" justifyContent="center" mb={2}>
                  <Avatar
                    src={IMAGE_BASE + data?.image} // Assuming this is the profile image
                    alt="Profile Icon"
                    sx={{ width: 100, height: 100 }} // Avatar size
                  />
                </Box>

                  <Stack spacing={2}>
                    <Typography variant="h6">Name</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.name || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Offer Type</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                       {capitalizeFirstLetter(data?.offerType) || ""}
                    </Typography>
                    <Divider></Divider>

                    {data?.couponCode ?
                    <>
                      <Typography variant="h6">Coupon Code</Typography>
                      <Typography
                        variant="body1"
                        sx={{ overflowWrap: "break-word" }}
                      >
                        {data?.couponCode || 'NA'}
                      </Typography>
                      <Divider></Divider>
                    </>
                    :null}
                    

                    {data?.couponType ?
                    <>
                      <Typography variant="h6">Coupon Type</Typography>
                      <Typography
                        variant="body1"
                        sx={{ overflowWrap: "break-word" }}
                      >
                        {capitalizeFirstLetter(data?.couponType) || 'NA'}
                      </Typography>
                      <Divider></Divider>
                    </>
                    :null}

                    {data?.minimumPrice?
                    <>
                        <Typography variant="h6">Minimum Price</Typography>
                        <Typography
                        variant="body1"
                        sx={{ overflowWrap: "break-word" }}
                        >
                        {data?.minimumPrice || ""}
                        </Typography>
                        <Divider></Divider>
                    </>
                    :""}

                    {data?.discount ?
                    <>
                    <Typography variant="h6">Discoun</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.discount || ""}
                    </Typography>
                    <Divider></Divider>
                    </>
                    :""}

                    <Typography variant="h6">Start Date</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {fDateTime(data?.startDate) || ""}
                    </Typography>
                    <Divider></Divider>

               

                    <Typography variant="h6">End Date</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {fDateTime(data?.endDate) || ""}
                    </Typography>
                    <Divider></Divider>

                    {data?.freeProductId ?
                      <>
                          <Typography variant="h6">Free Product List</Typography>
                          <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                            {data?.freeProductId?.name}
                          </Typography>
                          <Divider></Divider>
                      </>
                    :null}

                    {data?.purchaseProductIds && data.purchaseProductIds.length > 0 ?
                      <>
                        <Typography variant="h6">Purchase Product List</Typography>
                        <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                          {data.purchaseProductIds.map(product => product?.name).join(" , ") || ""}
                        </Typography>
                        <Divider></Divider>
                      </>
                    : null}

                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

export default OfferAndBannerView;