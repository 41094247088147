import { FormHelperText } from '@mui/material'
import React from 'react'

const FieldErrorMessage = ({ formik, name }) => {
    return (
        <FormHelperText
            error={Boolean(formik?.touched[name] && formik?.errors[name])}
            sx={{ marginLeft: "14px" }}
        >
            {formik?.touched[name] && formik?.errors[name]}
        </FormHelperText>
    )
}

export default FieldErrorMessage