import React, { useEffect, useState } from "react";
import { Grid, Card, Typography, CardHeader, CardContent, Stack, Box, Divider } from "@mui/material";
import { useNavigate, useParams , Link} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import { IMAGE_BASE } from "../../utils/constants";
import ApiService from "../../services/categoryManagementApi";
import { PictureAsPdf } from "@mui/icons-material";

const { dealerManagementApi } = ApiService;

const DealerView = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dealerManagementApi.getById({ id: id });
        setData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Box>
      <Helmet>
        <title>Dealer Management</title>
      </Helmet>
      <CardLayout>
        <CardHeader title="Dealer View" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                <CardContent>
                  <Stack spacing={2}>
                    <Typography variant="h6">Name</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.businessName || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Email</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.email || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Phone Number</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.phoneNumber || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Dealer Id</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.dealerId || ""}
                    </Typography>
                    <Divider></Divider>
                    {data?.documents && data?.documents.length > 0 && (
                      <>
                        <Typography variant="h6">Documents</Typography>
                        <Stack direction="row" spacing={2}>
                          {data?.documents.map((document, index) => (
                            <Box key={index}>
                              <Link
                                to={IMAGE_BASE + document}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none", color: "inherit" }}
                              >
                                <PictureAsPdf fontSize="large" sx={{ color: 'red' }} />
                                <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                                  {/* {` Document ${index + 1}`} */}
                                </Typography>
                              </Link>
                            </Box>
                          ))}
                        </Stack>
                        <Divider />
                      </>
                    )}                
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

export default DealerView;
