import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Card, Typography, CardHeader, CardContent, Stack, Button, Box, Divider} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import { Icon } from "@iconify/react";
import ApiService from "../../services/categoryManagementApi";

const { cmsApi } = ApiService;

const HeadStyle = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  margin: "0 0 25px 0",
});

const ViewCms = () => {
  const { id } = useParams();
  const [cmsData, setCmsData] = useState(null);
  const [status, setStatus] = useState()
  const navigate = useNavigate();
  const {state} = useLocation();
  const slug = state?.rowData?.slug

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await cmsApi.getById({ id });
        setCmsData(response.data.data);
        setStatus(response?.data?.status)
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  const handleEdit = () => {
    navigate(`/cms/edit/${id}`);
  };

  return (
    <Box>
      <Helmet>
        <title>CMS</title>
      </Helmet>
      <CardLayout>
        <Card>
          <CardHeader title="CMS" />
          <CardContent>
            <Grid item xs={12} md={12}>
              <Stack mb={5} spacing={1}>
                <Typography variant="subtitle1">Title</Typography>
                <Typography variant="body1">{cmsData?.title || ""}</Typography>
                <Divider/>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack mb={5} spacing={1}>
                <Typography variant="subtitle1">Slug</Typography>
                <Typography variant="body1">{cmsData?.slug || ""}</Typography>
                <Divider/>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack mb={5} spacing={1}>
                <Typography variant="subtitle1">Description</Typography>
                <Typography
                  variant="body1"
                  className="html-template"
                  dangerouslySetInnerHTML={{ __html: cmsData?.description }}
                ></Typography>
                <Divider/>
              </Stack>
            </Grid>
            {console.log("cms",cmsData)}
            <Grid item xs={12} md={12}>
              <Stack mb={5} spacing={1}>
                <Typography variant="subtitle1">Status</Typography>
                <Typography variant="body1">
                  {status ===1 ? "Active" : "Deactivate"}
                </Typography>
                <Divider/>
              </Stack>
            </Grid>
            <Stack
              spacing={2}
              mt={3}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                type="button"
                variant="contained"
                startIcon={<Icon icon="fa-solid:save" />}
                onClick={handleEdit}
              >
                Edit
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </CardLayout>
    </Box>
  );
};

export default ViewCms;