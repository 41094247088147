import {
  Grid,
  CardHeader,
  CardContent,
  Box,
  TextField,
} from "@mui/material";
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import errorHandler from "../../utils/errorHandler";
import LoadingButton from "@mui/lab/LoadingButton";
import CardLayout from "../../layouts/CardLayout";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { CustomerSchema } from "../../utils/Schema";
import ApiService from "../../services/categoryManagementApi";

const { customerManagementApi } = ApiService;

function AddCustomer() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null); 
  const fileInputRef = useRef(null);
  const documentInputRef = useRef(null); 
  const { id } = useParams();
  const [data, setData] = useState();

  const fetchTaskData = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await customerManagementApi.getById({ id });
        const resData = response?.data?.data;     

        setData(resData);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || "",
      email: data?.email || "",
      phoneNumber: data?.phoneNumber || "",
      registrationNumber: data?.registrationNumber || "",
      flatNumber: data?.flatNumber || "",
      area: data?.area || "",
      locality: data?.locality || "",
      landmark: data?.landmark || "",
      city: data?.city || "",
      village: data?.village || "",
      state: data?.state || "",
      pinCode: data?.pinCode || ""      
    },
    validationSchema: CustomerSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {

        const payload = id 
        ? { ...values, id } 
        : { ...values };

        const response = id
          ? await customerManagementApi.updateUser(payload)
          : await customerManagementApi.add(payload);

        toast.success(response?.data?.message, { toastId: "success" });
        navigate(-1);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
  });


  return (
    <Box>
      <Helmet>
        <title>{id ? "Edit" : "Add"} Customer</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`${id ? "Edit" : "Add"} Customer`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item mt={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    required
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    name="name"
                    error={Boolean(
                      formik.touched.name && formik.errors.name
                    )}
                    helperText={
                      formik.touched.name && formik.errors.name
                    }
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item mt={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name="email"
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    required
                    value={formik.values.phoneNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,10}$/.test(value)) { 
                        formik.setFieldValue("phoneNumber", value);
                      }
                    }}
                    name="phoneNumber"
                    type='text'
                    error={Boolean(
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    )}
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="ID No./Registration No."
                    required
                    value={formik.values.registrationNumber}
                    onChange={(e) => {
                      const value = e.target.value.toUpperCase(); // Convert input to uppercase
                      if (/^[A-Z0-9]*$/.test(value) && value.length <= 20) { // Check if only uppercase alphanumeric and <= 20 chars
                        formik.setFieldValue("registrationNumber", value);
                      }
                    }}
                    name="registrationNumber"
                    type="text"
                    error={Boolean(
                      formik.touched.registrationNumber && formik.errors.registrationNumber
                    )}
                    helperText={
                      formik.touched.registrationNumber && formik.errors.registrationNumber
                    }
                  />
                </Grid>
                
                {/* <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="ID No./Registration No."
                  required
                  value={formik.values.registrationNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,20}$/.test(value)) { // Replace 20 with your desired max length
                      formik.setFieldValue("registrationNumber", value);
                    }
                  }}
                  name="registrationNumber"
                  type="text" // Use text to enforce max length
                  error={Boolean(
                    formik.touched.registrationNumber && formik.errors.registrationNumber
                  )}
                  helperText={
                    formik.touched.registrationNumber && formik.errors.registrationNumber
                  }
                />

                </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Flat No./Plot No."
                    required
                    value={formik.values.flatNumber}
                    onChange={formik.handleChange}
                    name="flatNumber"
                    error={Boolean(formik.touched.flatNumber && formik.errors.flatNumber)}
                    helperText={formik.touched.flatNumber && formik.errors.flatNumber}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Area"
                    required
                    value={formik.values.area}
                    onChange={formik.handleChange}
                    name="area"
                    error={Boolean(formik.touched.area && formik.errors.area)}
                    helperText={formik.touched.area && formik.errors.area}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Locality"
                    required
                    value={formik.values.locality}
                    onChange={formik.handleChange}
                    name="locality"
                    error={Boolean(formik.touched.locality && formik.errors.locality)}
                    helperText={formik.touched.locality && formik.errors.locality}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Landmark"
                    required
                    value={formik.values.landmark}
                    onChange={formik.handleChange}
                    name="landmark"
                    error={Boolean(formik.touched.landmark && formik.errors.landmark)}
                    helperText={formik.touched.landmark && formik.errors.landmark}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="City"
                    required
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    name="city"
                    error={Boolean(formik.touched.city && formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Village"
                    required
                    value={formik.values.village}
                    onChange={formik.handleChange}
                    name="village"
                    error={Boolean(formik.touched.village && formik.errors.village)}
                    helperText={formik.touched.village && formik.errors.village}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="State"
                    required
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    name="state"
                    error={Boolean(formik.touched.state && formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Pin Code"
                    required
                    value={formik.values.pinCode}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,6}$/.test(value)) {
                        formik.setFieldValue("pinCode", value);
                      } else {
                        e.preventDefault();
                      }
                    }}
                    name="pinCode"
                    type="text"
                    error={Boolean(formik.touched.pinCode && formik.errors.pinCode)}
                    helperText={formik.touched.pinCode && formik.errors.pinCode}
                  />
                </Grid>

                <Grid item mt={4} xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                    sx={{ width: "500px" }} 
                  >
                    {id ? "Edit" : "Add"} Customer
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default AddCustomer;
